const types = [
  ['SALE', 'Venda'],
  ['RENT', 'Aluguel'],
  ['PRE_SALE', 'Pré Venda'],
  ['EXCHANGE', 'Permuta']
]

const locations = [
  ['APARTMENT', 'Apartamento'],
  ['ROOF', 'Cobertura'],
  ['HOUSE', 'Casa'],
  ['FARM', 'Fazenda'],
  ['CLINIC', 'Consultório'],
  ['SHED', 'Galpão'],
  ['GARAGE', 'Garagem'],
  ['KITNET', 'Kitnet'],
  ['LOT', 'Lote'],
  ['STORE', 'Loja'],
  ['COMERCIAL', 'Sala Comercial'],
  ['SITE', 'Sítio']
]

const status = [
  ['AVAILABLE', 'Disponível'],
  ['SOLD', 'Vendido'],
  ['RENTED', 'Alugado'],
  ['UNAVAILABLE', 'Indisponível']
]

export default {
  type: (value) => types.filter(el => el[0] === value)[0][1],

  location: (value) => locations.filter(el => el[0] === value)[0][1],

  status: (value) => status.filter(el => el[0] === value)[0][1]
}
