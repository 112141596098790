import React, { useState, useEffect } from 'react'
import {
  Card,
  Tabs,
  Row,
  Col,
  Form,
  Select,
  Switch,
  Input,
  Button,
  Upload,
  message
} from 'antd'
import { useParams, useHistory } from 'react-router-dom'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import InputMask from 'react-input-mask'
import axios from 'axios'
import arrayMove from 'array-move'

import AppLayout from '../../layouts/AppLayout'

import api from '../../services/api'
import geolocation from '../../services/geolocation'
import { getToken } from '../../guard/auth'

const { TabPane } = Tabs

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 16 }
}
const tailLayout = {
  wrapperCol: { offset: 5, span: 16 }
}
const types = [
  ['SALE', 'Venda'],
  ['RENT', 'Aluguel'],
  ['PRE_SALE', 'Pré Venda'],
  ['EXCHANGE', 'Permuta']
]
const locations = [
  ['APARTMENT', 'Apartamento'],
  ['ROOF', 'Cobertura'],
  ['HOUSE', 'Casa'],
  ['FARM', 'Fazenda'],
  ['CLINIC', 'Consultório'],
  ['SHED', 'Galpão'],
  ['GARAGE', 'Garagem'],
  ['KITNET', 'Kitnet'],
  ['LOT', 'Lote'],
  ['STORE', 'Loja'],
  ['COMERCIAL', 'Sala Comercial'],
  ['SITE', 'Sítio']
]

function PropertiesInfo() {
  const [loading, setLoading] = useState(true)
  const [updateLoading, setUpdateLoading] = useState(false)
  const [exterior, setExterior] = useState(false)
  const [data, setData] = useState({
    images: [],
    fees: [],
    features: [],
    information: {
      pet: false,
      comercial: false,
      furnished: false
    }
  })

  const [form] = Form.useForm()
  const [formFee] = Form.useForm()
  const [formFeature] = Form.useForm()
  const [formInfo] = Form.useForm()

  const history = useHistory()
  const { id } = useParams()

  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [id])

  async function getData(loadAgain = true) {
    if (loadAgain) {
      setLoading(true)
    }

    try {
      const { data } = await api.get(`/property/${id}`)

      if (data.is_exterior) {
        setExterior(true)
      }

      setData(data)
    } catch (err) {
      message.error('Ocorreu um erro ao buscar os dados')
    }

    setLoading(false)
  }

  async function onSubmit(values) {
    setUpdateLoading(true)

    try {
      await api.patch('/property/' + id, values)

      message.success('Imóvel atualizado!')
    } catch (err) {
      message.error(err.response.data[0].message)
    }

    setUpdateLoading(false)
  }

  async function onRemoveFee(id) {
    try {
      await api.delete(`/property/${id}/fees`)

      var oldState = data.fees
      var idx = data.fees.findIndex(el => el.id === id)

      oldState[idx] = undefined

      setData({ ...data, fees: oldState.filter(el => el !== undefined) })
    } catch (err) {
      message.error('Ocorreu um erro ao tentar remover a taxa')
    }
  }

  async function onRemoveImage(id) {
    try {
      await api.delete(`/property/${id}/images`)

      var oldState = data.images
      var idx = data.images.findIndex(el => el.id === id)

      oldState[idx] = undefined

      setData({ ...data, images: oldState.filter(el => el !== undefined) })
    } catch (err) {
      message.error('Ocorreu um erro ao tentar remover a imagem')
    }
  }

  async function onRemoveFeature(id) {
    try {
      await api.delete(`/property/${id}/features`)

      var oldState = data.features
      var idx = data.features.findIndex(el => el.id === id)

      oldState[idx] = undefined

      setData({ ...data, features: oldState.filter(el => el !== undefined) })
    } catch (err) {
      message.error('Ocorreu um erro ao tentar remover a característica')
    }
  }

  async function destroy() {
    message.loading('Aguarde...')

    try {
      await api.delete(`/property/${id}`)

      history.push('/properties')

      message.success('Imóvel removido')
    } catch (err) {
      message.error('Ocorreu um erro ao tentar remover o imóvel')
    }
  }

  const SortableList = SortableContainer(
    ({ items, isImage, isFee, isFeature }) => {
      return (
        <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
          {items.map((value, index) => (
            <SortableItem
              key={`item-${index}`}
              index={index}
              sortIndex={index}
              value={value}
              isImage={isImage}
              isFee={isFee}
              isFeature={isFeature}
            />
          ))}
        </ul>
      )
    }
  )

  const SortableItem = SortableElement(
    ({ value, isImage, isFee, isFeature }) => (
      <li style={{ marginBottom: 10 }}>
        {isImage && (
          <div style={{ position: 'relative', width: 'fit-content' }}>
            <img
              src={`https://api.hasteadm.com.br/uploads/${value.source}`}
              alt="..."
              height="100px"
            />
            <button
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                padding: 5,
                fontSize: '12px',
                cursor: 'pointer',
                border: 'none',
                background: 'red',
                color: 'white'
              }}
              onClick={() => onRemoveImage(value.id)}
            >
              Remover
            </button>
          </div>
        )}
        {isFee && (
          <p>
            {value.name} R$
            {parseFloat(value.price).toLocaleString('pt-BR', {
              minimumFractionDigits: 2
            })}
            <Button
              type="danger"
              size="small"
              style={{ float: 'right' }}
              onClick={() => onRemoveFee(value.id)}
            >
              Remover
            </Button>
          </p>
        )}
        {isFeature && (
          <p>
            {value.name}
            <Button
              type="danger"
              size="small"
              style={{ float: 'right' }}
              onClick={() => onRemoveFeature(value.id)}
            >
              Remover
            </Button>
          </p>
        )}
      </li>
    )
  )

  return (
    <AppLayout title="Ver imóvel" subKeyPage="sub2">
      <Card title="Informações do Imóvel" extra={(<Button onClick={destroy} type="danger">Deletar imóvel</Button>)} loading={loading}>
        <Tabs defaultActiveKey="6">
          <TabPane tab="Principal" key="6">
            <Form
              {...layout}
              name="register"
              form={form}
              onFinish={onSubmit}
              initialValues={data}
              onValuesChange={({ is_exterior: isExterior }) => {
                if (isExterior !== undefined) {
                  if (isExterior) {
                    form.setFieldsValue({ cep: '' })
                  }

                  setExterior(isExterior)
                }
              }}
              autoComplete="off"
              scrollToFirstError
            >
              <Form.Item label="Exterior" name="is_exterior">
                <Switch defaultChecked={exterior} />
              </Form.Item>
              {!exterior && (
                <Form.Item
                  label="CEP"
                  name="cep"
                  rules={[{ required: true, message: 'Informe o CEP' }]}
                >
                  <InputMask
                    mask="99.999-999"
                    onChange={async e => {
                      if (!e.target.value.includes('_')) {
                        var unformatted = e.target.value

                        unformatted = unformatted
                          .split('.')
                          .join('')
                          .split('-')
                          .join('')

                        const { data } = await axios.get(
                          `https://viacep.com.br/ws/${unformatted}/json/`
                        )

                        form.setFieldsValue({
                          street: data.logradouro,
                          neighborhood: data.bairro,
                          city: data.localidade,
                          state: data.uf,
                          latitude: '',
                          longitude: ''
                        })

                        form.scrollToField('number')
                      }
                    }}
                  >
                    {inputProps => <Input {...inputProps} />}
                  </InputMask>
                </Form.Item>
              )}
              <Form.Item
                label="Logradouro"
                name="street"
                rules={[{ required: true, message: 'Informe o Logradouro' }]}
              >
                <Input readOnly={!exterior} />
              </Form.Item>
              <Form.Item
                label="Bairro"
                name="neighborhood"
                rules={[{ required: true, message: 'Informe o Bairro' }]}
              >
                <Input readOnly={!exterior} />
              </Form.Item>
              <Form.Item
                label="Cidade"
                name="city"
                rules={[{ required: true, message: 'Informe a Cidade' }]}
              >
                <Input readOnly={!exterior} />
              </Form.Item>
              <Form.Item
                label="Estado"
                name="state"
                rules={[{ required: true, message: 'Informe o Estado' }]}
              >
                <Input readOnly={!exterior} />
              </Form.Item>
              <Form.Item
                label="Número"
                name="number"
                rules={[{ required: true, message: 'Informe o Número' }]}
              >
                <Input
                  onBlur={async () => {
                    const {
                      street,
                      neighborhood,
                      number,
                      city,
                      cep
                    } = form.getFieldsValue()
                    const location = await geolocation(
                      `${street}, ${number}, ${neighborhood} - ${city} ${cep}`
                    )

                    form.setFieldsValue({
                      ...location
                    })
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Quartos"
                name="bedrooms"
                rules={[
                  { required: true, message: 'Informe o número de quartos' }
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Banheiros"
                name="bathrooms"
                rules={[
                  { required: true, message: 'Informe o número de banheiros' }
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Vagas na Garagem"
                name="garage"
                rules={[
                  { required: true, message: 'Informe o número de vagas' }
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item label="Localidade">
                <Input.Group compact>
                  <Form.Item
                    name="latitude"
                    rules={[{ required: true, message: 'Informe a Latitude' }]}
                  >
                    <Input readOnly />
                  </Form.Item>
                  <Form.Item
                    name="longitude"
                    rules={[{ required: true, message: 'Informe a Longitude' }]}
                  >
                    <Input readOnly />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
              <Form.Item label="Complemento" name="complement">
                <Input />
              </Form.Item>
              <Form.Item
                label="Tipo"
                name="type"
                rules={[{ required: true, message: 'Informe o tipo' }]}
              >
                <Select>
                  {types.map((type, idx) => (
                    <Select.Option key={`type-${idx}`} value={type[0]}>
                      {type[1]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Locação"
                name="location"
                rules={[{ required: true, message: 'Informe a locação' }]}
              >
                <Select>
                  {locations.map((location, idx) => (
                    <Select.Option key={`location-${idx}`} value={location[0]}>
                      {location[1]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Preço"
                name="price"
                rules={[{ required: true, message: 'Informe o preço' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item {...tailLayout}>
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  disabled={updateLoading}
                  loading={updateLoading}
                >
                  Salvar alterações
                </Button>
              </Form.Item>
            </Form>
          </TabPane>
          <TabPane tab="Informações" key="4">
            <Form
              {...layout}
              form={formInfo}
              name="add-info"
              initialValues={data.information}
            >
              <Form.Item
                label="Descrição"
                name="description"
                rules={[{ required: true, message: 'Informe a descrição' }]}
              >
                <Input.TextArea placeholder="Descreva o imóvel..." rows={4} />
              </Form.Item>
              <Form.Item
                label="Área"
                name="area"
                rules={[{ required: true, message: 'Informe a área' }]}
              >
                <Input type="number" />
              </Form.Item>
              <Form.Item label="Permitido PET" name="pet">
                <Switch
                  defaultChecked={
                    data.information ? data.information.pet : false
                  }
                />
              </Form.Item>
              <Form.Item label="Mobiliado" name="furnished">
                <Switch
                  defaultChecked={
                    data.information ? data.information.furnished : false
                  }
                />
              </Form.Item>
              <Form.Item label="Comércio Próx." name="comercial">
                <Switch
                  defaultChecked={
                    data.information ? data.information.comercial : false
                  }
                />
              </Form.Item>
              <Form.Item {...tailLayout}>
                <Button
                  htmlType="submit"
                  onClick={async () => {
                    const { description, area } = formInfo.getFieldsValue()
                    const data = formInfo.getFieldsValue()

                    if (description && area) {
                      try {
                        await api.put(`/property/${id}/information`, data)

                        message.success('Atualizado!')
                      } catch (err) {
                        message.error(
                          'Ocorreu algum erro ao tentar atualizar as informações'
                        )
                      }
                    }
                  }}
                >
                  Atualizar
                </Button>
              </Form.Item>
            </Form>
          </TabPane>
          <TabPane tab="Características" key="1">
            <Form form={formFeature} name="add-feature">
              <Row gutter={15}>
                <Col md={18}>
                  <Form.Item
                    name="name"
                    rules={[{ required: true, message: 'Informe o nome' }]}
                  >
                    <Input placeholder="Informe a característica" />
                  </Form.Item>
                </Col>
                <Col md={6}>
                  <Form.Item>
                    <Button
                      htmlType="submit"
                      onClick={async () => {
                        const { name } = formFeature.getFieldsValue()

                        if (name) {
                          try {
                            await api.put(`/property/${id}/features`, {
                              data: [name]
                            })

                            setData({
                              ...data,
                              features: [...data.features, { name }]
                            })
                            formFeature.resetFields()
                          } catch (err) {
                            message.error(
                              'Ocorreu algum erro ao tentar cadastrar uma característica'
                            )
                          }
                        }
                      }}
                    >
                      Cadastrar
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <SortableList
              isFeature
              items={data.features}
              pressDelay={200}
              onSortEnd={({ oldIndex, newIndex }) => {
                var newPosition = arrayMove(data.features, oldIndex, newIndex)

                setData({ ...data, features: newPosition })

                api
                  .patch(`/property/${id}/features/position`, {
                    data: newPosition
                  })
                  .catch(() => {
                    message.error('Ocorreu um erro ao tentar mudar a posição')
                  })
              }}
            />
          </TabPane>
          <TabPane tab="Taxas" key="2">
            <Form
              form={formFee}
              name="add-fee"
              onSubmit={() => message.success('Enviado')}
            >
              <Row gutter={15}>
                <Col md={9}>
                  <Form.Item
                    name="name"
                    rules={[{ required: true, message: 'Informe o nome' }]}
                  >
                    <Input placeholder="Nome da Taxa" />
                  </Form.Item>
                </Col>
                <Col md={9}>
                  <Form.Item
                    name="price"
                    rules={[{ required: true, message: 'Informe o valor' }]}
                  >
                    <Input placeholder="Valor da Taxa" />
                  </Form.Item>
                </Col>
                <Col md={6}>
                  <Form.Item>
                    <Button
                      htmlType="submit"
                      onClick={async () => {
                        const { name, price } = formFee.getFieldsValue()

                        if (name && price) {
                          try {
                            await api.put(`/property/${id}/fees`, {
                              data: [
                                {
                                  name,
                                  price
                                }
                              ]
                            })

                            setData({
                              ...data,
                              fees: [...data.fees, { name, price }]
                            })
                            formFee.resetFields()
                          } catch (err) {
                            message.error(
                              'Ocorreu algum erro ao tentar cadastrar uma taxa'
                            )
                          }
                        }
                      }}
                    >
                      Cadastrar
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <SortableList
              isFee
              items={data.fees}
              pressDelay={200}
              onSortEnd={({ oldIndex, newIndex }) => {
                var newPosition = arrayMove(data.fees, oldIndex, newIndex)

                setData({ ...data, fees: newPosition })

                api
                  .patch(`/property/${id}/fees/position`, {
                    data: newPosition
                  })
                  .catch(() => {
                    message.error('Ocorreu um erro ao tentar mudar a posição')
                  })
              }}
            />
          </TabPane>
          <TabPane tab="Imagens" key="3">
            <Upload
              action={`https://api.hasteadm.com.br/property/${id}/images`}
              method="PUT"
              headers={{
                Authorization: `Bearer ${getToken()}`
              }}
              multiple
              onChange={e => {
                if (e.file.status === 'done') {
                  getData(false)
                  message.success('Imagem enviada com sucesso')
                }
              }}
            >
              <Button>Clique para fazer upload</Button>
            </Upload>
            <br />
            <SortableList
              isImage
              items={data.images}
              pressDelay={200}
              onSortEnd={({ oldIndex, newIndex }) => {
                var newPosition = arrayMove(data.images, oldIndex, newIndex)

                setData({ ...data, images: newPosition })

                api
                  .patch(`/property/${id}/images/position`, {
                    data: newPosition
                  })
                  .catch(() => {
                    message.error('Ocorreu um erro ao tentar mudar a posição')
                  })
              }}
            />
          </TabPane>
        </Tabs>
      </Card>
    </AppLayout>
  )
}

export default PropertiesInfo
