import { createGlobalStyle } from 'styled-components'

export const Styles = createGlobalStyle`
  html, body {
    min-height: 100vh
  }

  body {
    font-family: 'Source Sans Pro', sans-serif;
  }

  #components-layout-demo-responsive .logo {
    height: 32px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px;
  }

  .site-layout-sub-header-background {
    background: #fff;
  }

  .site-layout-background {
    background: #fff;
  }

  .ant-layout-sider {
    .logo {
      text-align: center;
      padding: 25px 0;

      img {
        max-height: 60px;
      }
    }
  }

  .site-layout-sub-header-background {
    ul {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      padding: 0 25px;
      list-style: none;

      li {
        margin-left: 25px;

        a {
          color: #616161;
          font-weight: 600;
          font-size: 16px;
        }
      }
    }
  }

  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: rgb(255, 67, 1);
  }

  .note-group-select-from-files {
    display: none
  }
`
