import React, { useState, useEffect } from 'react'
import { Table, Button, Card, Row, Col, Form, Input, Select, message } from 'antd'

import AppLayout from '../../layouts/AppLayout'

import api from '../../services/api'
import perm from '../../helpers/variables/permissions'

function Permissions () {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])

  const [form] = Form.useForm()

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      responsive: ['md'],
      sorter: (a, b) => a.id - b.id
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Ações',
      render: (text, record) => {
        return (
          <Button type="danger" onClick={() => destroy(record.id)}>Deletar</Button>
        )
      }
    }
  ]

  useEffect(() => {
    getData()
  }, [])

  async function destroy (id) {
    try {
      await api.delete(`/user/group?id=${id}`)
      getData()
    } catch (err) {
      message.error('Não foi possível deletar este grupo')
    }
  }

  async function getData () {
    setLoading(true)

    try {
      const { data: groups } = await api.get('/user/groups')

      setData(groups.map(group => {
        return {
          key: group.id,
          ...group
        }
      }))
    } catch (err) {
      message.error('Ocorreu um erro ao buscar os dados')
    }

    setLoading(false)
  }

  async function create (values) {
    message.loading('Cadastrando...')

    try {
      await api.post('/user/group', values)

      getData()
    } catch (err) {
      message.error('Ocorreu um erro ao tentar criar um novo grupo de permissão')
    }
  }

  return (
    <AppLayout title="Permissões" keyPage="18" subKeyPage="sub3">
      <Row gutter={25}>
        <Col md={12}>
          <Card title="Criar novo grupo de permissão">
            <Form
              name="create-user"
              form={form}
              onFinish={create}
              autoComplete="off"
            >
              <Form.Item
                name="name"
                rules={[{ required: true, message: 'Informe o nome do grupo!' }]}
              >
                <Input placeholder="Nome" />
              </Form.Item>
              <Form.Item
                name="permissions"
                rules={[{ required: true, message: 'Informe o nome do grupo!' }]}
              >
                <Select
                  mode="multiple"
                  placeholder="Permissões"
                  optionLabelProp="label"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {perm.variables.map((permission, idx) => (
                    <Select.Option
                      key={idx}
                      value={permission.value}
                      label={permission.name}
                    >
                      {permission.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Cadastrar
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
        <Col md={12}>
          <Card title="Grupos criados">
            <Table
              columns={columns}
              dataSource={data}
              locale={{ emptyText: 'Não há usuários cadastrados' }}
              loading={loading}
            />
          </Card>
        </Col>
      </Row>
    </AppLayout>
  )
}

export default Permissions
