import React, { useState } from 'react'
import { Typography, Table, Tag, Row, Col, Card } from 'antd'
import { Line, Pie } from '@ant-design/charts'

import AppLayout from '../../layouts/AppLayout'

import api from '../../services/api'

import translate from '../../helpers/translate/properties'

const { Title } = Typography

function Home () {
  const [dash, setDash] = useState(null)
  const [loading, setLoading] = useState(true)

  const [data, setData] = useState([])
  const [dataPie, setDataPie] = useState([])

  const config = {
    data,
    title: {
      visible: false
    },
    xField: 'date',
    yField: 'value',
    seriesField: 'type',
    responsive: true
  }

  const configPie = {
    forceFit: true,
    title: {
      visible: false
    },
    description: {
      visible: false
    },
    radius: 0.8,
    data: dataPie,
    angleField: 'visitas',
    colorField: 'type',
    label: {
      visible: true,
      type: 'inner'
    }
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Endereço',
      dataIndex: 'address',
      key: 'address'
    },
    {
      title: 'Tipo',
      dataIndex: 'type',
      key: 'type',
      render: (text) => translate.type(text)
    },
    {
      title: 'Locação',
      dataIndex: 'location',
      key: 'location',
      render: (text) => translate.location(text)
    },
    {
      title: 'Preço',
      dataIndex: 'price',
      key: 'price',
      render: text => 'R$' + parseFloat(text).toLocaleString('pt-BR', { minimumFractionDigits: 2 })
    },
    {
      title: 'Visitas',
      dataIndex: 'views',
      key: 'views',
      render: text => (
        <Tag color="magenta">
          {text} visitas
        </Tag>
      )
    }
  ]

  React.useEffect(() => {
    getData()
  }, [])

  async function getData () {
    setLoading(true)

    try {
      const { data: resultData } = await api.get('/property/dash')

      setDash({
        popular: resultData.popular.map(el => ({
          key: el.id,
          ...el,
          address: `${el.street}, ${el.number} - ${el.neighborhood}`
        }))
      })

      setData(resultData.resume)

      var pieChart = []

      resultData.popular.forEach(el => {
        if (pieChart.length < 6) {
          pieChart.push({
            type: `Imóvel ${el.id}`,
            visitas: el.views
          })
        }
      })

      setDataPie(pieChart)
    } catch (err) {
      alert('Ocorreu um erro ao tentar recuperar dados')
    }

    setLoading(false)
  }

  return (
    <AppLayout title="Dashboard" keyPage="1">
      <Row gutter={25}>
        <Col md={16} span={24}>
          <Card loading={loading}>
            <Title>Resumo</Title>
            <Line {...config} />
          </Card>
        </Col>
        <Col md={8} span={24}>
          <Card loading={loading}>
            <Title level={3}>Imóveis em alta</Title>
            <br />
            <Pie {...configPie} />
          </Card>
        </Col>
      </Row>
      <br />
      <Card title="Alta possibilidade de locação">
        <Table columns={columns} dataSource={dash ? dash.popular : []} loading={loading} />
      </Card>
    </AppLayout>
  )
}

export default Home
