import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Table, Card, Button, message } from 'antd'

import AppLayout from '../../layouts/AppLayout'

import api from '../../services/api'

function PropertiesInterests () {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      responsive: ['md'],
      sorter: (a, b) => a.id - b.id
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Telefone',
      dataIndex: 'phone',
      key: 'phone'
    },
    {
      title: 'Ações',
      render: (text, record) => {
        return (
          <>
            <Link to={`/properties/info/${record.property_id}`}>Ver imóvel</Link>
            <Button
              type="danger"
              style={{ marginLeft: 10 }}
              onClick={() => remove(record.id)}
            >
              Deletar
            </Button>
          </>
        )
      }
    }
  ]

  useEffect(() => {
    getData()
  }, [])

  async function getData () {
    setLoading(true)

    try {
      const { data: interests } = await api.get('/property/interests')

      setData(interests.map(property => ({
        ...property,
        key: property.id
      })))
    } catch (err) {
      message.error('Ocorreu um erro ao buscar os dados')
    }

    setLoading(false)
  }

  async function remove (id) {
    try {
      await api.delete('/property/interest/' + id)
      getData()
    } catch (err) {
      message.error('Ocorreu um erro ao deletar')
    }
  }

  return (
    <AppLayout title="Imóveis" keyPage="11" subKeyPage="sub2">
      <Card title="Intereses">
        <Table
          columns={columns}
          dataSource={data}
          locale={{ emptyText: 'Não há interesses pendentes' }}
          loading={loading}
        />
      </Card>
    </AppLayout>
  )
}

export default PropertiesInterests
