import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Form, Input, Button, Alert } from 'antd'

import { Page, Card } from './styles'
import { Styles } from '../../components/'

import api from '../../services/api'
import { setData } from '../../guard/auth'

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 }
}
const tailLayout = {
  wrapperCol: { offset: 5, span: 19 }
}

function Login () {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const history = useHistory()

  const onFinish = async (values) => {
    setLoading(true)

    try {
      const { data } = await api.post('/user/session', values)

      setData(data)
      history.push('/')

      return setError(null)
    } catch (err) {
      const { message } = err.response.data[0]

      if (!message) {
        setError('Erro interno do servidor, tente novamente mais tarde.')
      } else {
        setError(message)
      }
    }

    setLoading(false)
  }

  return (
    <Page>
      <Styles />
      <img src={require('../../assets/images/logo.svg')} alt="MB Imobiliária" height="80px" />
      <Card>
        <h1>Autentifique-se</h1>
        {error && <Alert message={error} type="error" style={{ marginBottom: 25 }} />}
        <Form
          {...layout}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label="E-mail"
            name="email"
            type="email"
            rules={[{ required: true, message: 'Informe seu e-mail!' }, { type: 'email', message: 'Informe um e-mail válido' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Senha"
            name="password"
            rules={[{ required: true, message: 'Informe sua senha!' }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button size="large" type="primary" htmlType="submit" loading={loading}>
              Entrar
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Page>
  )
}

export default Login
