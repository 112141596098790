import React, { useState } from 'react'
import { Button, Card, Row, Col, Form, Input, Select, message } from 'antd'

import CKEditor from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

import AppLayout from '../../layouts/AppLayout'

import api from '../../services/api'

function Template() {
  const [loading, setLoading] = useState(false)
  const [area, setArea] = useState(null)
  const [body, setBody] = useState('<p><br /></p>')

  const [form] = Form.useForm()

  async function getData(area) {
    setLoading(true)

    try {
      const { data } = await api.get('/template/' + area)

      if (area === 'ABOUT') {
        if (data.body !== 'Não definido, favor preencher') {
          setBody(data.body)
        }
      } else {
        form.setFieldsValue(data)
      }
    } catch (err) {
      console.log(err)
      message.error('Ocorreu um erro ao buscar os dados')
    }

    setLoading(false)
  }

  async function submit(values) {
    message.loading('Atualizando...')

    try {
      await api.put('/template', area === 'ABOUT' ? { area, body } : values)

      message.success('Atualizado!')
    } catch (err) {
      message.error('Ocorreu um erro ao tentar atualizar')
    }
  }

  return (
    <AppLayout title="Template" keyPage="14">
      <Row gutter={25}>
        <Col md={24}>
          <Card title="Adicionar ou criar nova personalização">
            <Form
              name="update"
              form={form}
              layout="vertical"
              onFinish={submit}
              autoComplete="off"
              onValuesChange={obj => {
                if (obj.area) {
                  getData(obj.area)
                  setArea(obj.area)
                }
              }}
            >
              <Form.Item
                name="area"
                label="Área de customização"
                rules={[
                  {
                    required: true,
                    message: 'Informe a area que deseja customizar!'
                  }
                ]}
              >
                <Select>
                  <Select.Option value="FOOTER_ADDRESS">
                    Endereço [RODAPÉ]
                  </Select.Option>
                  <Select.Option value="FOOTER_HOURS">
                    Horário [RODAPÉ]
                  </Select.Option>
                  <Select.Option value="FOOTER_COPYRIGHT">
                    Copyright [RODAPÉ]
                  </Select.Option>
                  <Select.Option value="ABOUT">
                    Conteúdo da página Sobre
                  </Select.Option>
                </Select>
              </Form.Item>
              {area === 'ABOUT' ? (
                <CKEditor
                  editor={ClassicEditor}
                  data={body}
                  onInit={editor => {
                    console.log('Editor is ready to use!', editor)
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData()
                    setBody(data)
                  }}
                  onBlur={(event, editor) => {
                    console.log('Blur.', editor)
                  }}
                  onFocus={(event, editor) => {
                    console.log('Focus.', editor)
                  }}
                />
              ) : (
                <Form.Item
                  name="body"
                  rules={[{ required: true, message: 'Informe o corpo' }]}
                >
                  <Input.TextArea rows={10} />
                </Form.Item>
              )}
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  disabled={loading}
                >
                  Atualizar
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </AppLayout>
  )
}

export default Template
