import React, { useState, useEffect } from 'react'
import { Table, Button, Card, Modal, message } from 'antd'
import nl2br from 'react-nl2br'

import AppLayout from '../../layouts/AppLayout'

import api from '../../services/api'

function Clients () {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const [modalShow, setModalShow] = useState(false)
  const [msg, setMsg] = useState('')

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      responsive: ['md']
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Telefone',
      dataIndex: 'phone',
      key: 'phone'
    },
    {
      title: 'Enviado em',
      dataIndex: 'created_at',
      key: 'created_at',
      render: text => new Date(text).toLocaleString('pt-BR')
    },
    {
      title: 'Ações',
      render: (text, record) => {
        return (
          <>
            <Button
              type="ghost"
              onClick={() => {
                setModalShow(true)
                setMsg(record.message)
              }}
              style={{ marginRight: 10 }}
            >
              Ver mensagem
            </Button>
            <Button type="danger" onClick={() => remove(record.id)}>Remover</Button>
          </>
        )
      }
    }
  ]

  useEffect(() => {
    getData()
  }, [])

  async function getData () {
    setLoading(true)

    try {
      const { data: contacts } = await api.get('/contact')

      setData(contacts.map(contact => ({ ...contact, key: contact.id })))
    } catch (err) {
      message.error('Ocorreu um erro ao buscar os dados')
    }

    setLoading(false)
  }

  async function remove (id) {
    try {
      await api.delete(`/contact/${id}`)

      var oldState = data
      var idx = oldState.findIndex(el => el.id === id)

      oldState[idx] = undefined

      setData(oldState.filter(el => el !== undefined))
    } catch (err) {
      message.error('Não foi possível remover o contato')
    }
  }

  return (
    <AppLayout title="Contatos" keyPage="16">
      <Card title="Mensagens recebidas pelo site">
        <Modal
          title="Mensagem"
          visible={modalShow}
          onOk={() => setModalShow(!modalShow)}
          onCancel={() => setModalShow(!modalShow)}
          cancelText="Fechar"
          okText="Ok!"
        >
          <p>
            {nl2br(msg)}
          </p>
        </Modal>
        <Table
          columns={columns}
          dataSource={data}
          locale={{ emptyText: 'Não há contatos por aqui' }}
          loading={loading}
        />
      </Card>
    </AppLayout>
  )
}

export default Clients
