import React from 'react'
import { HashRouter, Switch, Route } from 'react-router-dom'

import PrivateRoute from './components/PrivateRoute'

import NotFound from './pages/NotFound'
import Login from './pages/Login'

import Home from './pages/Home'

import Properties from './pages/Properties'
import PropertiesRegister from './pages/Properties/register'
import PropertiesInfo from './pages/Properties/info'
import PropertiesCondominium from './pages/Properties/condominium'
import PropertiesInterests from './pages/Properties/interests'

import Contacts from './pages/Contacts'

import Blog from './pages/Blog'

import Users from './pages/Users'
import Permissions from './pages/Permissions'
import Template from './pages/Template'

function Routes () {
  return (
    <HashRouter basename="/">
      <Switch>
        <PrivateRoute exact path="/" component={Home} />

        <PrivateRoute exact path="/properties" component={Properties} guard="PROPERTY_VIEW" />
        <PrivateRoute exact path="/properties/register" component={PropertiesRegister} guard="PROPERTY_CREATE" />
        <PrivateRoute exact path="/properties/condominium" component={PropertiesCondominium} guard="CONDOMINIUM_VIEW" />
        <PrivateRoute exact path="/properties/interests" component={PropertiesInterests} guard="PROPERTY_INTERESTS" />
        <PrivateRoute exact path="/properties/info/:id" component={PropertiesInfo} guard="PROPERTY_INTEREST" />

        <PrivateRoute exact path="/contacts" component={Contacts} guard="CONTACT_VIEW" />

        <PrivateRoute exact path="/template" component={Template} guard="TEMPLATE_VIEW" />

        <PrivateRoute exact path="/blog" component={Blog} guard="BLOG_VIEW" />

        <PrivateRoute exact path="/settings/users" component={Users} guard="ADMIN_USER_VIEW" />
        <PrivateRoute exact path="/settings/permissions" component={Permissions} guard="ADMIN_GROUP_VIEW" />

        <Route exact path="/login" component={Login} />
        <Route path="*" component={NotFound} />
      </Switch>
    </HashRouter>
  )
}

export default Routes
