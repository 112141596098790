import React from 'react'
import PropTypes from 'prop-types'
import { Button, Modal } from 'antd'

function CondominiumButton ({ data }) {
  const [show, setShow] = React.useState(false)

  return (
    <>
      <Button type="primary" onClick={() => setShow(!show)}>
        Abrir detalhes
      </Button>
      <Modal
        title="Abrir detalhes"
        visible={show}
        onOk={() => setShow(false)}
        okText="Fechar"
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <p>
          <b>Nome do condomínio:</b> {data.name} <br />
          <b>CEP:</b> {data.cep} <br />
          <b>Logradouro:</b> {data.street} <br />
          <b>Bairro:</b> {data.neighborhood} <br />
          <b>Número:</b> {data.number} <br />
          <b>Cidade:</b> {data.city} <br />
          <b>Estado:</b> {data.state} <br />
          <b>Nome do Responsável:</b> {data.username} <br />
          <b>Cargo do Responsável:</b> {data.office} <br />
          <b>Telefone do Responsável:</b> {data.phone} <br />
          <b>WhatsApp do Responsável:</b> {data.whatsapp}
        </p>
      </Modal>
    </>
  )
}

CondominiumButton.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    cep: PropTypes.string,
    street: PropTypes.string,
    neighborhood: PropTypes.string,
    city: PropTypes.string,
    number: PropTypes.string,
    state: PropTypes.string,
    username: PropTypes.string,
    office: PropTypes.string,
    phone: PropTypes.string,
    whatsapp: PropTypes.string
  })
}

export default CondominiumButton
