import React, { useState, useEffect } from 'react'
import { Table, Button, Card, Row, Col, Form, Input, Select, message } from 'antd'

import AppLayout from '../../layouts/AppLayout'

import api from '../../services/api'

function Users () {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const [groups, setGroups] = useState([])

  const [form] = Form.useForm()

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      responsive: ['md'],
      sorter: (a, b) => a.id - b.id
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      key: 'email',
      responsive: ['md']
    },
    {
      title: 'Grupo',
      dataIndex: 'groupName',
      key: 'groupName',
      responsive: ['md']
    },
    {
      title: 'Ações',
      render: (text, record) => {
        return (
          <Button type="danger" onClick={() => destroy(record.id)}>Deletar</Button>
        )
      }
    }
  ]

  useEffect(() => {
    getData()
  }, [])

  async function destroy (id) {
    try {
      await api.delete(`/user/user?id=${id}`)
      getData()
    } catch (err) {
      message.error('Não foi possível deletar este usuário')
    }
  }

  async function getData () {
    setLoading(true)

    try {
      const { data: users } = await api.get('/user')
      const { data: groups } = await api.get('/user/groups')

      setGroups(groups)
      setData(users.map(user => ({ ...user, key: user.id, groupName: user.group[0].name })))
    } catch (err) {
      message.error('Ocorreu um erro ao buscar os dados')
    }

    setLoading(false)
  }

  async function create (values) {
    message.loading('Cadastrando...')

    try {
      await api.post('/user', values)

      getData()
    } catch (err) {
      message.error('Ocorreu um erro ao tentar criar um novo usuário')
    }
  }

  return (
    <AppLayout title="Usuários" keyPage="17" subKeyPage="sub3">
      <Row gutter={25}>
        <Col md={8}>
          <Card title="Cadastre um novo usuário">
            <Form
              name="create-user"
              form={form}
              onFinish={create}
              autoComplete="off"
            >
              <Form.Item
                name="name"
                rules={[{ required: true, message: 'Informe o nome do usuário!' }]}
              >
                <Input placeholder="Nome" />
              </Form.Item>
              <Form.Item
                name="email"
                rules={[{ required: true, message: 'Informe o e-mail do usuário!' }, { type: 'email', message: 'Informe um e-mail válido' }]}
              >
                <Input placeholder="E-mail" />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[{ required: true, message: 'Informe a senha do usuário!' }]}
              >
                <Input
                  type="password"
                  placeholder="Senha"
                />
              </Form.Item>
              <Form.Item
                name="groupId"
                rules={[{ required: true, message: 'Informe o grupo do usuário!' }]}
              >
                <Select placeholder="Nível de permissão" >
                  {groups.map(group => (
                    <Select.Option key={group.id} value={group.id}>{group.name}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Cadastrar
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
        <Col md={16}>
          <Card title="Usuários cadastrados">
            <Table
              columns={columns}
              dataSource={data}
              locale={{ emptyText: 'Não há usuários cadastrados' }}
              loading={loading}
            />
          </Card>
        </Col>
      </Row>
    </AppLayout>
  )
}

export default Users
