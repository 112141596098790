import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Layout, Menu } from 'antd'
import {
  DashboardOutlined,
  HomeOutlined,
  BoldOutlined,
  MailOutlined,
  SettingOutlined,
  LayoutOutlined
} from '@ant-design/icons'
import { Link, useHistory } from 'react-router-dom'

import { clear } from '../../guard/auth'

import { Styles } from '../../components'

const { Header, Content, Footer, Sider } = Layout
const { SubMenu } = Menu

function AppLayout({ title, children, keyPage, subKeyPage }) {
  const history = useHistory()

  return (
    <React.Fragment>
      <Helmet>
        <title>{title} - Haste</title>
      </Helmet>
      <Styles />
      <Layout>
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          onBreakpoint={broken => {
            console.log(broken)
          }}
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type)
          }}
          style={{
            minHeight: '100vh'
          }}
        >
          <div className="logo">
            <img
              src={require('../../assets/images/logo.svg')}
              alt="Haste - Logo"
            />
          </div>
          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={[keyPage]}
            defaultOpenKeys={[subKeyPage]}
          >
            <Menu.Item key="1" icon={<DashboardOutlined />}>
              <Link to="/" />
              Dashboard
            </Menu.Item>
            <SubMenu key="sub2" icon={<HomeOutlined />} title="Imóveis">
              <Menu.Item key="8">
                <Link to="/properties" />
                Gerenciar
              </Menu.Item>
              <Menu.Item key="9">
                <Link to="/properties/register" />
                Cadastrar
              </Menu.Item>
              <Menu.Item key="10">
                <Link to="/properties/condominium" />
                Condomínio
              </Menu.Item>
              <Menu.Item key="11">
                <Link to="/properties/interests" />
                Interesses
              </Menu.Item>
            </SubMenu>
            <Menu.Item key="15" icon={<BoldOutlined />}>
              <Link to="/blog" />
              Blog
            </Menu.Item>
            <Menu.Item key="16" icon={<MailOutlined />}>
              <Link to="/contacts" />
              Contato
            </Menu.Item>
            <Menu.Item key="14" icon={<LayoutOutlined />}>
              <Link to="/template" />
              Personalização
            </Menu.Item>
            <SubMenu
              key="sub3"
              icon={<SettingOutlined />}
              title="Configurações"
            >
              <Menu.Item key="17">
                <Link to="/settings/users" />
                Usuários
              </Menu.Item>
              <Menu.Item key="18">
                <Link to="/settings/permissions" />
                Permissões
              </Menu.Item>
            </SubMenu>
          </Menu>
        </Sider>
        <Layout>
          <Header
            className="site-layout-sub-header-background"
            style={{ padding: 0 }}
          >
            <ul>
              <li>
                <a
                  href="!#"
                  onClick={() => {
                    clear()
                    history.push('/login')
                  }}
                >
                  Sair da conta
                </a>
              </li>
            </ul>
          </Header>
          <Content style={{ margin: '24px 16px 0' }}>{children}</Content>
          <Footer style={{ textAlign: 'center' }}>
            MB Imobiliária &copy; Desenvolvido por Kodesky
          </Footer>
        </Layout>
      </Layout>
    </React.Fragment>
  )
}

AppLayout.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.object.isRequired,
  keyPage: PropTypes.string.isRequired,
  subKeyPage: PropTypes.string
}

export default AppLayout
