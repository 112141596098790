import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #001529;

  h1, h2 {
    margin: 0;
    color: #fff;
  }

  h1 {
    font-weight: 900;
    font-size: 42px;
    line-height: 42px;
    margin-right: 15px;
  }

  h2 {
    font-weight: 200;
    margin-top: 10px;
    margin-left: 10px;
    border-left: solid 1px rgba(255, 255, 255, .6);
    padding-left: 15px;
    font-size: 24px;
    line-height: 24px;
    text-transform: uppercase;
  }
`
