import axios from 'axios'
import api from './api'

export default async (query) => {
  const { data } = await api.get('/property/geolocalization', {
    params: {
      query
    }
  }).catch(err => console.log('Erro na Geolocalização', err.response))

  const { latitude, longitude } = data
  
  return { latitude, longitude }
}
