/* eslint-disable react/prop-types */
import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import PropTypes from 'prop-types'

import { getToken } from '../../guard/auth'
import permissions from '../../helpers/variables/permissions'

function PrivateRoute ({ guard, component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        getToken() ? guard ? permissions.has(guard)
          ? (
            <Component {...props} />
          )
          : (
            <Redirect
              to={{
                pathname: '/',
                state: { from: props.location }
              }}
            />
          )
          : (
            <Component {...props} />
          ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  )
}

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  children: PropTypes.object
}

export default PrivateRoute
