import { getPermissions } from '../../guard/auth'

const script = {
  all: () => {
    return getPermissions().map(item => item.permission)
  },
  has: (value) => {
    var permissions = script.all()

    if (permissions.includes('*')) {
      return true
    }

    return permissions.includes(value)
  },
  variables: [
    {
      name: 'Visualizar clientes',
      value: 'USER_VIEW'
    },
    {
      name: 'Visualizar imóveis',
      value: 'PROPERTY_VIEW'
    }
  ]
}

export default script
