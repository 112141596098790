import React, { useState, useEffect } from 'react'
import { Table, Card, Button, message } from 'antd'

import AppLayout from '../../layouts/AppLayout'

import api from '../../services/api'

import CondominiumButton from '../../components/CondominiumButton'

function PropertiesCondominium () {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      responsive: ['md'],
      sorter: (a, b) => a.id - b.id
    },
    {
      title: 'Nome do Condomínio',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Endereço',
      dataIndex: 'address',
      key: 'address',
      render: (text, record) => `${record.street}, ${record.neighborhood} - ${record.number}`
    },
    {
      title: 'Solicitado em',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text) => new Date(text).toLocaleString('pt-BR')
    },
    {
      title: 'Ações',
      render: (text, record) => {
        return (
          <>
            <CondominiumButton data={record} />
            <Button
              type="danger"
              style={{ marginLeft: 10 }}
              onClick={() => remove(record.id)}
            >
              Remover
            </Button>
          </>
        )
      }
    }
  ]

  useEffect(() => {
    getData()
  }, [])

  async function getData () {
    setLoading(true)

    try {
      const { data: condominiums } = await api.get('/condominium')

      setData(condominiums.map(el => ({
        ...el,
        key: el.id
      })))
    } catch (err) {
      message.error('Ocorreu um erro ao buscar os dados')
    }

    setLoading(false)
  }

  async function remove (id) {
    try {
      await api.delete('/condominium/' + id)
      getData()
    } catch (err) {
      message.error('Ocorreu um erro ao deletar')
    }
  }

  return (
    <AppLayout title="Imóveis" keyPage="10" subKeyPage="sub2">
      <Card title="Solicitações de Condomínio">
        <Table
          columns={columns}
          dataSource={data}
          locale={{ emptyText: 'Não há imóveis cadastrados' }}
          loading={loading}
        />
      </Card>
    </AppLayout>
  )
}

export default PropertiesCondominium
