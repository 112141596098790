import React from 'react'
import ReactDOM from 'react-dom'
import GoogleFontLoader from 'react-google-font-loader'

import 'antd/dist/antd.min.css'
import './assets/styles/fullcalendar-core.css'
import './assets/styles/fullcalendar-daygrid.css'
import './assets/styles/fullcalendar-theme.css'

import Routes from './routes'

ReactDOM.render(
  <React.Fragment>
    <GoogleFontLoader
      fonts={[
        {
          font: 'Source Sans Pro',
          weights: [200, 300, 400, 600, 700, 900]
        }
      ]}
    />
    <Routes />
  </React.Fragment>,
  document.getElementById('root')
)
