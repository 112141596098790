import React, { useState } from 'react'
import { Card, Button, Select, Switch, Input, Form, message } from 'antd'
import { useHistory } from 'react-router-dom'
import InputMask from 'react-input-mask'
import axios from 'axios'

import AppLayout from '../../layouts/AppLayout'

import api from '../../services/api'
import geolocation from '../../services/geolocation'

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 14 }
}

const tailLayout = {
  wrapperCol: { offset: 5, span: 14 }
}

const types = [
  ['SALE', 'Venda'],
  ['RENT', 'Aluguel'],
  ['PRE_SALE', 'Pré Venda'],
  ['EXCHANGE', 'Permuta']
]

const locations = [
  ['APARTMENT', 'Apartamento'],
  ['ROOF', 'Cobertura'],
  ['HOUSE', 'Casa'],
  ['FARM', 'Fazenda'],
  ['CLINIC', 'Consultório'],
  ['SHED', 'Galpão'],
  ['GARAGE', 'Garagem'],
  ['KITNET', 'Kitnet'],
  ['LOT', 'Lote'],
  ['STORE', 'Loja'],
  ['COMERCIAL', 'Sala Comercial'],
  ['SITE', 'Sítio']
]

function PropertiesRegister () {
  const [loading, setLoading] = useState(false)
  const [exterior, setExterior] = useState(false)

  const history = useHistory()
  const [form] = Form.useForm()

  async function onSubmit (values) {
    setLoading(true)

    try {
      const { data: { id } } = await api.post('/property', values)

      history.push(`/properties/info/${id}`)
    } catch (err) {
      message.error(err.response.data[0].message)
    }

    setLoading(false)
  }

  return (
    <AppLayout title="Imóveis" keyPage="9" subKeyPage="sub2">
      <Card title="Cadastrar novo imóvel">
        <Form
          {...layout}
          name="register"
          form={form}
          onFinish={onSubmit}
          onValuesChange={({ is_exterior: isExterior }) => {
            if (isExterior !== undefined) {
              if (isExterior) {
                form.setFieldsValue({ cep: '' })
              }

              setExterior(isExterior)
            }
          }}
          autoComplete="off"
          scrollToFirstError
        >
          <Form.Item label="Exterior" name="is_exterior">
            <Switch />
          </Form.Item>
          {!exterior && (
            <Form.Item
              label="CEP"
              name="cep"
              rules={[{ required: true, message: 'Informe o CEP' }]}
            >
              <InputMask mask="99.999-999" onChange={async (e) => {
                if (!e.target.value.includes('_')) {
                  var unformatted = e.target.value

                  unformatted = unformatted.split('.').join('').split('-').join('')

                  const { data } = await axios.get(`https://brasilapi.com.br/api/cep/v1/${unformatted}`)

                  form.setFieldsValue({
                    street: data.street,
                    neighborhood: data.neighborhood,
                    city: data.city,
                    state: data.state,
                    latitude: '',
                    longitude: '',
                  })

                  form.scrollToField('number')
                }
              }}>
                {(inputProps) => <Input {...inputProps} />}
              </InputMask>
            </Form.Item>
          )}
          <Form.Item
            label="Logradouro"
            name="street"
            rules={[{ required: true, message: 'Informe o Logradouro' }]}
          >
            <Input readOnly={!exterior} />
          </Form.Item>
          <Form.Item
            label="Bairro"
            name="neighborhood"
            rules={[{ required: true, message: 'Informe o Bairro' }]}
          >
            <Input readOnly={!exterior} />
          </Form.Item>
          <Form.Item
            label="Cidade"
            name="city"
            rules={[{ required: true, message: 'Informe a Cidade' }]}
          >
            <Input readOnly={!exterior} />
          </Form.Item>
          <Form.Item
            label="Estado"
            name="state"
            rules={[{ required: true, message: 'Informe o Estado' }]}
          >
            <Input readOnly={!exterior} />
          </Form.Item>
          <Form.Item
            label="Número"
            name="number"
            rules={[{ required: true, message: 'Informe o Número' }]}
          >
            <Input onBlur={async () => {
              const { street, neighborhood, number, city, cep } = form.getFieldsValue()
              const location = await geolocation(`${street}, ${number}, ${neighborhood} - ${city} ${cep}`)
              
              form.setFieldsValue({
                ...location
              })
            }} />
          </Form.Item>
          <Form.Item label="Localidade">
            <Input.Group compact>
              <Form.Item
                name='latitude'
                rules={[{ required: true, message: 'Informe a Latitude' }]}
              >
                <Input readOnly />
              </Form.Item>
              <Form.Item
                name='longitude'
                rules={[{ required: true, message: 'Informe a Longitude' }]}
              >
                <Input readOnly />
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <Form.Item
            label="Complemento"
            name="complement"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Tipo"
            name="type"
            rules={[{ required: true, message: 'Informe o tipo' }]}
          >
            <Select>
              {types.map((type, idx) => (
                <Select.Option key={`type-${idx}`} value={type[0]}>{type[1]}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Locação"
            name="location"
            rules={[{ required: true, message: 'Informe a locação' }]}
          >
            <Select>
              {locations.map((location, idx) => (
                <Select.Option key={`location-${idx}`} value={location[0]}>{location[1]}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Preço"
            name="price"
            rules={[{ required: true, message: 'Informe o preço' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button size="large" type="primary" htmlType="submit" disabled={loading} loading={loading}>
              Cadastrar
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </AppLayout>
  )
}

export default PropertiesRegister
